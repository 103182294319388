import { Component, OnInit, NgZone, Renderer2, ViewChild, ElementRef, AfterViewInit  } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LoadingComponent } from '../loading/loading.component';

import { GlobalsService } from '../../shared/services/globals.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  @ViewChild('iframe')
  private iframeRef: ElementRef;

url = 'https://script.google.com/macros/s/AKfycbzKo6x3tWizOf0Mm6GmNKMIPP6R8kuPF4ZwS0amX1n9lxjtZjkO/exec?v=profile';
trustedUrl: SafeResourceUrl;

 isLoaded = false;
 year: string;
 s: String = '&y=2020&email=';
  constructor(
    public authService: AuthService,
    public router: Router,
    public ngZone: NgZone,
    public loadingComponent: LoadingComponent,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    public globals: GlobalsService
  ) {
    this.globals.chosenYear.subscribe(year => {
      this.year = year;
      this.s = '&y=' + year + '&email=';
      this.loadIframe();
    });
   }

  ngOnInit() {
    
   }
   loadIframe() {
    this.isLoaded  = false;
    if (this.authService.userData.email) {
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url + this.s + this.authService.userData.email);
      } else {
       this.url = 'https://script.google.com/macros/s/AKfycbzKo6x3tWizOf0Mm6GmNKMIPP6R8kuPF4ZwS0amX1n9lxjtZjkO/exec?v=405';
       this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      }
  }
   ngAfterViewInit() {
    this.renderer.listen(this.iframeRef.nativeElement, 'load', (e) => { this.isLoaded = true; });
  }


}
