import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';



// Reactive Form
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

// App routing modules
import { AppRoutingModule } from './shared/routing/app-routing.module';

// App components
import { AppComponent } from './app.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { BudgetComponent } from './components/budget/budget.component';
import { IndexComponent } from './components/index/index.component';
import { LoadingComponent } from './components/loading/loading.component';
import { InfoComponent } from './components/info/info.component';
import { DailySentenceComponent } from './shared/daily-sentence/daily-sentence.component';

// Firebase services + enviorment module
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';

// Auth service
import { AuthService } from './shared/services/auth.service';
import { MyRequestsComponent } from './components/myrequests/myrequests.component';
import { RequestComponent } from './components/request/request.component';
import { SuppliersComponent } from './components/suppliers/suppliers.component';
import { InfomMsgComponent } from './components/infom-msg/infom-msg.component';






@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    SignUpComponent,
    DashboardComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    BudgetComponent,
    IndexComponent,
    LoadingComponent,
    InfoComponent,
    MyRequestsComponent,
    RequestComponent,
    SuppliersComponent,
    InfomMsgComponent,
    DailySentenceComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    NgbModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [AuthService, LoadingComponent],
  bootstrap: [AppComponent]
})

export class AppModule { }
