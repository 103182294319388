
<div class="m-4" dir="rtl" style="text-align: right">
	<h1>ברוכים הבאים לאפליקציית המרכז המשאבי</h1>
	{{year}}
    <h3>הבקשות האחרונות שלך:</h3>
    <app-loading [isLoadedC]="isLoaded" ></app-loading>

    <iframe #iframe id="iframe" sandbox="allow-scripts allow-popups allow-forms allow-same-origin allow-popups-to-escape-sandbox" 
    frameborder="0" aria-label="Apps Script" 
    [src]="trustedUrl" allowfullscreen="true" style="height: 100%;" scrolling="no"></iframe>
    <h3>פעולות נפוצות:</h3>
	<div class="d-sm-flex" dir="rtl">
		<a routerLink="/budget" class="btn btn-outline-primary m-2">
			<div class="card p-2  img"  >
				<img src="assets/img/budget.svg" class="card-img-top" alt="..." style="max-width: 10rem; align-self: center;">
				<div class="card-body ">
					<h5 class="card-title">תקציב מול ביצוע</h5>
					<p class="card-text d-none d-md-block d-none d-md-block">כאן ניתן לצפות בתקציב המלא שבאחריותך וכמו כן לצפות בתמונת המצב העדכנית של ביצוע התקציב </p>
				</div>
			</div>
		</a>
		<a routerLink="/request" class="btn btn-outline-primary m-2">
			<div class="card p-2  img"  >
				<img src="assets/img/new-file.svg" class="card-img-top" alt="..." style="max-width: 10rem; align-self: center;">
				<div class="card-body">
					<h5 class="card-title">בקשת תקציב</h5>
					<p class="card-text d-none d-md-block">כאן ניתן ליצור בקשת חדשה לתקציב לדוגמה להזמין הסעה או קופה קטנה, כמו כן ניתן להעביר בקשה על הכנסה חדשה </p>
				</div>
			</div>
		</a>
		<a routerLink="/myreq" class="btn btn-outline-primary m-2">
			<div class="card p-2  img"  >
				<img src="assets/img/contact-list.svg" class="card-img-top" alt="..." style="max-width: 10rem; align-self: center;">
				<div class="card-body">
					<h5 class="card-title">הבקשות שלי</h5>
					<p class="card-text d-none d-md-block">כאן ניתן למצוא את כלל הבקשות <strong>שאני</strong>  ביקשתי. כולל בקשות שהסתיימו ולצפות בסטטוס הנוכחי שלהן. האם הן שולמו האם התקבלו וכו...</p>
				</div>
			</div>
		</a>
		<a routerLink="/suppliers" class="btn btn-outline-primary m-2">
			<div class="card p-2  img"  >
				<img src="assets/img/actions.svg" class="card-img-top" alt="..." style="max-width: 10rem; align-self: center;">
				<div class="card-body">
					<h5 class="card-title">רשימת ספקים</h5>
					<p class="card-text d-none d-md-block">בירור סטטוס ספק, הכנסת פרטים של ספק חדש ובדיקה עם איזה ספקים אנחנו עובדים</p>
				</div>
			</div>
		</a>
		<a routerLink="/info" class="btn btn-outline-primary m-2">
			<div class="card p-2  img"  >
				<img src="assets/img/information.svg" class="card-img-top" alt="..." style="max-width: 10rem; align-self: center;">
				<div class="card-body">
					<h5 class="card-title">מידע חשוב</h5>
					<p class="card-text d-none d-md-block">מספרי טלפון, מספרי ח.פ. כתובת, מסמכים חשובים, דוחות כספיים, כל מה שצריך נמצא כאן</p>
				</div>
			</div>
		</a>
	</div>
</div>

