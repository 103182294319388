import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { AuthService } from './shared/services/auth.service';

import { GlobalsService } from './shared/services/globals.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    public authService: AuthService,
    public router: Router,
   public globals: GlobalsService
  ) {
    this.globals.chosenYear.subscribe(year => this.year = year);
  }
  isUser: boolean;
  public isNavbarCollapsed = true;
  year: string;
  cYear: number =  new Date().getFullYear();
  choosenYear: number = new Date().getFullYear();
  text: string;

  title = 'angularfirebase-authentication';

  ngOnInit() {
    this.authService.getIsLoggedIn().subscribe(isLogged => this.isUser = isLogged  );
  }

  clickedNavItem(text) {
  this.isNavbarCollapsed = false;
   this.text = 'ניסית להיכנס ל' + text + ', על מנת לצפות בדף זה יש להיכנס למערכת';
  }

  changeCurrentYear(year: number) {
    this.choosenYear = year;
    const string = year.toString();
    this.globals.changeYear(string);
  }
}


