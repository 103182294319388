import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { LoadingComponent } from '../loading/loading.component';

import { GlobalsService } from '../../shared/services/globals.service'

@Component({
  selector: 'app-my-requests',
  templateUrl: './myrequests.component.html',
  styleUrls: ['./myrequests.component.css']
})
export class MyRequestsComponent implements OnInit {
  @ViewChild('iframe') 
  private iframeRef: ElementRef;	
  email = '';
  url: string = "https://script.google.com/macros/s//exec?v=myreq";
  url2: string = "https://script.google.com/macros/s//exec?v=budget";
  trustedUrl: SafeResourceUrl;

  isLoaded = false;
  isBudget = false;
  year: string;
  s: String = '&y=2020&email='
  constructor(
    public authService: AuthService,
    private renderer: Renderer2,
    public loadingComponent: LoadingComponent,
    private sanitizer: DomSanitizer,
    public globals: GlobalsService
  ) {
    this.email = this.authService.userData.email;
    this.globals.getUrl('myreq').snapshotChanges().subscribe(o => {
       this.url = o.payload.data().url + '?v=myreq';
       this.url2 = o.payload.data().url + '?v=budget';
       console.log(this.url)
       this.loadIframe()
      });
   }

  ngOnInit(): void {
    this.globals.chosenYear.subscribe(year => {
      this.year = year;
      this.s = '&y=' + year + '&email='
      this.loadIframe()
    })
  }
  loadIframe(){
    this.isLoaded  = false
    if (this.authService.userData.email){
      this.url = this.url
      this.url2 = this.url2 
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url + this.s + this.email);
      } else {
       this.url = "https://script.google.com/macros/s/AKfycbzKo6x3tWizOf0Mm6GmNKMIPP6R8kuPF4ZwS0amX1n9lxjtZjkO/exec?v=405"
       this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      }
  }

   ngAfterViewInit() {
    this.renderer.listen(this.iframeRef.nativeElement, 'load', (e) => { this.isLoaded = true; })
  }
  changeUrl (){
    console.log("click")
    this.isBudget = !this.isBudget
    this.isLoaded = false;
    if(this.isBudget){
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url2 + this.s + this.email);
    }else{
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url + this.s + this.email);
    }
  }
}
