import { Component, OnInit, Renderer2, ViewChild, ElementRef, AfterViewInit  } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { AuthService } from '../../shared/services/auth.service';
import { LoadingComponent } from '../loading/loading.component';

import { GlobalsService } from '../../shared/services/globals.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit, AfterViewInit {
  @ViewChild('iframe')
  private iframeRef: ElementRef;

  url = 'https://script.google.com/macros/s/AKfycbzKo6x3tWizOf0Mm6GmNKMIPP6R8kuPF4ZwS0amX1n9lxjtZjkO/exec?v=firstfive';
  trustedUrl: SafeResourceUrl;
  isLoaded = false;
  year: string;
  s: String = '&y=2020&email=';

  constructor(
    public authService: AuthService,
    public router: Router,
    private renderer: Renderer2,
    public loadingComponent: LoadingComponent,
    private sanitizer: DomSanitizer,
    public globals: GlobalsService
    ) {
      this.loadIframe();
    }

  ngOnInit(): void {
    this.globals.chosenYear.subscribe(year => {
      this.year = year;
      this.s = '&y=' + year + '&email=';
      this.loadIframe();
    });
  }

  loadIframe() {
    this.isLoaded  = false;

    if (this.authService.userData.email) {
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url + this.s + this.authService.userData.email);
      } else {
       this.url = 'https://script.google.com/macros/s/AKfycbzKo6x3tWizOf0Mm6GmNKMIPP6R8kuPF4ZwS0amX1n9lxjtZjkO/exec?v=405';
       this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      }
  }

  ngAfterViewInit() {
    this.renderer.listen(this.iframeRef.nativeElement, 'load', (e) => { this.isLoaded = true; });
  }
}
