<div class="displayTable">
  <div class="displayTableCell">

    <div class="px-logo">
      <a href="" target="_blank">
        <img src="assets/budgetLogo.jpg" alt="shmutz icon">
      </a>
    </div>

    <div class="authBlock" >
      <h3>הרשמה</h3>

      <div class="formGroup" > 
        <input type="texy" class="formControl" placeholder="שם משתמש" #userName required >
      </div>

      <div class="formGroup">
        <input type="email" class="formControl" placeholder='כתובת דוא"ל' #userEmail required>
      </div>

      <div class="formGroup">
        <input type="password" class="formControl" placeholder="סיסמה" #userPwd required>
      </div>

      <div class="formGroup">
        <input type="button" class="btn btnPrimary" value="Sign Up" (click)="authService.SignUp(userEmail.value, userPwd.value, userName.value)">
      </div>

      <div class="formGroup">
        <span class="or"><span class="orInner">או</span></span>
      </div>

      <!-- Continue with Google -->
      <div class="formGroup">
        <button type="button" class="btn googleBtn" (click)="authService.GoogleAuth()">
          <i class="fab fa-google-plus-g"></i>
          Google  המשיכו עם חשבון 
        </button>
      </div>
      
    </div>

    <div class="redirectToLogin">
      <span>כבר יש לך חשבון? <span class="redirect" routerLink="/sign-in">כניסה</span></span>
    </div>
  </div>

</div>
