import { Component, OnInit, Renderer2, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { LoadingComponent } from '../loading/loading.component';

import { GlobalsService } from '../../shared/services/globals.service';

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.css']
})
export class BudgetComponent implements OnInit, AfterViewInit {
  @ViewChild('iframe')
  private iframeRef: ElementRef;

  url = 'https://script.google.com/macros/s/AKfycbw9drtDjkO6NEE8-wXLjBBBRwryHLrRSlM9G6ErwvjpT8ddZgQ/exec?';
  trustedUrl: SafeResourceUrl;

  isLoaded = false;
  year: string;
  s: String = '&y=2020&email=';

  constructor(
    public authService: AuthService,
    private renderer: Renderer2,
    public loadingComponent: LoadingComponent,
    private sanitizer: DomSanitizer,
    public globals: GlobalsService
  ) {
    this.globals.getUrl('budget').snapshotChanges().subscribe(o => {
      this.url = o.payload.data().url;
     });
   }

  ngOnInit(): void {
    this.globals.cUser.subscribe(user => {
      if (user.email) {
        this.globals.chosenYear.subscribe(year => {
          console.log( 'isLoaded : boolean = ', this.isLoaded);
          this.year = year;
          this.s = 'y=' + year + '&email=';
          this.loadIframe(user);
        });
      }
      });


  }
  loadIframe(user) {
    this.isLoaded  = false;
    console.log('user: ', user);
    if (user.email) {
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url + this.s + user.email);
      } else {
       this.url = 'https://script.google.com/macros/s/AKfycbzKo6x3tWizOf0Mm6GmNKMIPP6R8kuPF4ZwS0amX1n9lxjtZjkO/exec?v=405';
       this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      }

  }
  ngAfterViewInit() {
    this.renderer.listen(this.iframeRef.nativeElement, 'load', (e) => { this.isLoaded = true; });
  }

}
