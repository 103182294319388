import { Component, HostListener, OnInit } from '@angular/core';
import { GlobalsService, Sentence } from '../services/globals.service';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-daily-sentence',
  templateUrl: './daily-sentence.component.html',
  styleUrls: ['./daily-sentence.component.css']
})
export class DailySentenceComponent implements OnInit {
sentence: Sentence;
newSentence = new Sentence;
source: string;
moreInfo: string;
icon = 'close';
newIcon = 'add'
submitted = false;

sentences: Sentence[]
innerWidth: number = window.innerWidth;
isOpen = true;
isNewOpen = false;
@HostListener('window:resize', ['$event'])
onResize(event) {
  this.innerWidth = window.innerWidth;
}
  constructor(    public globals: GlobalsService, public authService: AuthService) { }


  ngOnInit(): void {
    this.retrieveSentences();
  }

  retrieveSentences(): void {
    this.globals.getAll().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(data => {
      this.sentences = data ;
      const index = Math.floor(Math.random() * this.sentences.length);
      this.setActiveSentence( index);
    })
  }

  setActiveSentence( index): void {
    this.sentence = this.sentences[index];
    console.log(this.sentence,'gggg',index)
  }

  saveTutorial(){
    
    this.newSentence.source = this.authService.userData.displayName;
    if (this.newSentence.hasOwnProperty('text')){
    this.globals.create(this.newSentence).then(() => {
      console.log('Created new item successfully!');
      window.alert('הציטוט נוסף בהצלחה. תודה')
      this.sentence = this.newSentence;
      this.submitted = true;
      this.toggle()
      this.isNewOpen = false;
    });
  }
  }

toggle(){
  this.isOpen = !this.isOpen;
}
toggleNew(){
  this.isNewOpen = !this.isNewOpen;
  this.newIcon = this.isNewOpen ? 'close' : 'add'
  this.isOpen = true
}

}
