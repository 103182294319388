import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from './user';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class GlobalsService {


  private dbPath = '/sentences';
  private dbPathGasUrls = '/gasUrl';

  SentenceRef: AngularFirestoreCollection<Sentence> = null;
  UrlRef: AngularFirestoreDocument<UrlObj> = null;

  private year = new BehaviorSubject(new Date().getFullYear().toString());
  chosenYear = this.year.asObservable();

  u: User = {   uid: "",email:"",displayName:"",photoURL:"",emailVerified:false};
  private user = new BehaviorSubject(this.u);
  cUser = this.user.asObservable();


  constructor(private db: AngularFirestore) {
    this.SentenceRef = db.collection(this.dbPath);
     }

  changeYear(year: string) {
    this.year.next(year)
  }
  changeUser(user: User) {
    this.user.next(user)
  }

  getAll(): AngularFirestoreCollection<Sentence> {
    console.log('ggg', this.SentenceRef)
    return this.SentenceRef;
  }

  getUrl(url): AngularFirestoreDocument<UrlObj> {

    return  this.db.doc(this.dbPathGasUrls + '/' + url);
  }

  create(sentence: Sentence): any {
    return this.SentenceRef.add({ ...sentence });
  }
}

export class Sentence {
  id: string;
  text: string;
  source: string;
  moreInfo?: string;

}

export class UrlObj {
  url: string;

}
