import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { LoadingComponent } from '../loading/loading.component';

import { GlobalsService } from '../../shared/services/globals.service'

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.css']
})
export class SuppliersComponent implements OnInit {
  @ViewChild('iframe') 
  private iframeRef: ElementRef;	

  url : string = "https://script.google.com/macros/s/AKfycbzKo6x3tWizOf0Mm6GmNKMIPP6R8kuPF4ZwS0amX1n9lxjtZjkO/exec?v=suppliers";
  trustedUrl: SafeResourceUrl;

  isLoaded : boolean = false
  year: string;
  s: String = '&y=2020&email='
  constructor( 
    private renderer: Renderer2,
    public loadingComponent: LoadingComponent,
    private sanitizer: DomSanitizer,
    public globals: GlobalsService
    ) { 
    
  }

  ngOnInit(): void {
    this.globals.chosenYear.subscribe(year => {
      this.year = year;
      this.s = '&y=' + year + '&email='
      this.loadIframe()
    })
  }

  loadIframe(){
    this.isLoaded  = false
    this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url + this.s );
  }
  
  ngAfterViewInit() {
    this.renderer.listen(this.iframeRef.nativeElement, 'load', (e) => { this.isLoaded = true; })
  }
}
