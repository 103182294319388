<div  class="container ">
    <div class="row ">
        <div class="col p-2 m-2">
            <h1>הנהלת חשבונות</h1>
            <p>שרית מיכאלי, אודליה אפרהימי</p>
            <p>שעות מענה: ימים ב, ג, ד בשעות 9:00-14:00</p>
            <p> טלפון: 058-45805811</p>  
            <p> :דוא"ל </p>
            <p>
                <strong>acct.nachshonim@gmail.com</strong>  הנה"ח בתי נוער הנחשונים ע"ר  
            <br>
                <strong>acct.t.b.shomrit@gmail.com </strong> הנה"ח תנועת הבוגרים השומרית ע"ר 
            <br>
            <strong>yehihakol@gmail.com</strong> הנה"ח יהי הכל שייך לכל בע"מ 
            </p> 
        </div>
        <div class="col p-2 m-2">
            <h2>תאגידים</h2>
            <h4>בתי נוער הנחשונים </h4>
            <p>ח.פ. 580511095</p>
            <p>פרטי חשבון בנק:</p>
            <p>חשבון רגיל 12/643/479732   </p>
            <p>חשבון לתרומות עבור ביה"ס לשלום 12/643/363963</p>
            <p><strong><a href="mailto:batey.n.h@gmail.com" subject="לעמותת בתי נוער הנחשונים">batey.n.h@gmail.com</a></strong> :דוא"ל </p>
            <h4>תנועת הבוגרים השומרית</h4>
            <p>ח.פ. 580558542</p>
            <p>פרטי חשבון בנק:</p>
            <p> מס' חשבון רגיל 12/532/516435</p>
            <p>מס' חשבון נכסים לייעוד 12/532/553381</p>
            <p><strong><a href="mailto:t.b.shomrit@gmail.com" subject="לעמותת תנועת הבוגרים השומרית">t.b.shomrit@gmail.com</a></strong> :דוא"ל </p>
        </div>
    </div> 
    <div class="row">
        <div class="col p-2 m-2">
            <h2>משרדים</h2>
            <h4>בנימינה</h4>
            <p>כתובת: הטחנה 46 בנימינה ת.ד 238  מיקוד 3052642</p>
            <h4>חיפה</h4>
            <p>כתובת: שער פלמר 1, חיפה</p>
        </div>
    </div>
</div>

    
    



