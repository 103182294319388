import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-infom-msg',
  templateUrl: './infom-msg.component.html',
  styleUrls: ['./infom-msg.component.css']
})
export class InfomMsgComponent implements OnInit {
@Input()
isLogged:boolean;

@Input()
text:string;
  constructor() { }

  ngOnInit(): void {
  }

}
