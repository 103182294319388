
<div class="box d-flex flex-row justify-content-between hover" *ngIf="sentence && innerWidth > 700 && isOpen" >
    <button type="button" class="btn " (click)="toggle()" >    
        <span class="material-icons">
        {{icon}}
        </span>
    </button>
    

    <div class="box d-flex flex-column justify-content-center">
        
        <div style="align-self: center" >
            <blockquote class="blockquote"  [hidden]="isNewOpen">
                <p class="mb-0">"{{sentence.text}}"</p>
                <footer class="blockquote-footer">{{sentence.source}}</footer>
            </blockquote>

            <div class="submit-form" [hidden]="!isNewOpen">
                הוספת ציטוט משלך (השם שלך יופיע לצידו)
                <div class="form-inline m-2">
                  <div class="form-group mx-sm-3 mb-2">
                    <label for="title">ציטוט</label>
                    <input
                      type="text"
                      class="form-control"
                      id="title"
                      required
                      [(ngModel)]="newSentence.text"
                      name="text"
                    />
                  </div>
              
                  <div class="form-group mx-sm-3 mb-2">
                    <label for="description">הסבר</label>
                    <textarea
                      class="form-control"
                      id="description"
                      [(ngModel)]="newSentence.moreInfo"
                      name="moreInfo"
                    ></textarea>
                  </div>
              
                  <button (click)="saveTutorial()" class="btn btn-success mx-sm-3 mb-2">שלחו</button>
                </div>

  
           
    </div>
    <div style="align-self: center; width: 100%" class="show-hover " *ngIf="sentence.moreInfo && !isNewOpen">
      <p style="align-self: center; max-width: 600px;">{{sentence.moreInfo}}</p>
  </div>
</div>
        
</div>
    <button type="button" class="btn show-hover " (click)="toggleNew()" *ngIf="!submitted && authService.isLoggedIn">    
        <span class="material-icons">
        {{newIcon}}
    </span>
    </button>
    
</div>
