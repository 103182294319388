<nav class="navbar navbar-expand-md bg-cYear navbar-dark" dir="rtl"  [ngClass]="{'bg-cYear': cYear === choosenYear, 'bg-nYear':cYear < choosenYear , 'bg-lYear':cYear > choosenYear}">
  <!-- Brand -->
  <a routerLink="/index" class="navbar-brand  mr-0">
    <img class="brand-logo" src="assets/logo2.png" alt="shmutz Logo" style="max-height: 4rem;">
  </a>

  <!-- Toggler/collapsibe Button -->
  <button class="navbar-toggler" type="button" data-toggle="collapse" (click)="isNavbarCollapsed = !isNavbarCollapsed" data-target="#collapsibleNavbar">
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- Navbar links -->
  <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="collapsibleNavbar">
    <ul class="navbar-nav ml-auto">
        <li class="nav-item">
            <a class="nav-link" routerLink="/request" routerLinkActive="active" (click)="clickedNavItem($event.target.innerText)">בקשה חדשה</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/myreq" routerLinkActive="active" (click)="clickedNavItem($event.target.innerText)">הבקשות שלי</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/info" routerLinkActive="active" (click)="clickedNavItem($event.target.innerText)">מידע חשוב</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/suppliers" routerLinkActive="active" (click)="clickedNavItem($event.target.innerText)"> ספקים</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/budget" routerLinkActive="active" (click)="clickedNavItem($event.target.innerText)">תקציב</a>
        </li>
        
    </ul>
        <ul class="navbar-nav ml-4">
        <li ngbDropdown class="nav-item">
                <a href (click)="false" class="nav-link" ngbDropdownToggle>שנת תקציב: {{year}}</a>
                <div ngbDropdownMenu>
                    <button style="text-align: center;" (click)="changeCurrentYear((cYear -1))" ngbDropdownItem>{{cYear -1}}</button>
                    <button style="text-align: center;" (click)="changeCurrentYear(cYear)" ngbDropdownItem>{{cYear }}</button>
                    <button style="text-align: center;" (click)="changeCurrentYear((cYear +1))" ngbDropdownItem>{{cYear +1}}</button>
                    <div class="dropdown-divider"></div>
                    <span style="display: flex; justify-content: center">שנה נוכחית: {{cYear}}</span>
                    <span style="display: flex; justify-content: center">שנה מוצגת: {{choosenYear}}</span>
                </div>
        </li>
        <li class="nav-item" [hidden]="!isUser">
            <a class="nav-link" routerLink="/dashboard" routerLinkActive="active" (click)="clickedNavItem($event.target.innerText)">
                <i class="fas fa-user ml-1"></i>פרטי משתמש
            </a>
        </li>
        <li class="nav-item" [hidden]="!isUser">
            <a class="nav-link" (click)="authService.SignOut()" (click)="isNavbarCollapsed = true">
                <i class="fas fa-sign-out-alt ml-1"></i>יציאה
            </a>
        </li>
        <li class="nav-item" [hidden]="isUser"> 
            <a class="nav-link" routerLink="/register-user" (click)="isNavbarCollapsed = true" >
                <i class="fas fa-user-plus ml-1"></i>הרשמה
            </a>
        </li>
        <li class="nav-item" [hidden]="isUser">
            <a class="nav-link" routerLink="/sign-in" (click)="isNavbarCollapsed = true" > 
                <i class="fas fa-sign-in-alt ml-1"></i>כניסה
            </a>
        </li>
    </ul>
  </div>
</nav>
<app-infom-msg [isLogged]="isUser" [text]="text"></app-infom-msg>

<router-outlet></router-outlet>
<footer class="navbar  fixed-bottom navbar-light bg-light">
    <app-daily-sentence class="w-100"></app-daily-sentence>
  </footer>