import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {
  @Input() isLoadedC: boolean;

  text : string;
  rand: number;
  constructor() { }

  ngOnInit(): void {
    this.rand = Math.floor(Math.random() * 9) 

    switch (this.rand) {
      case 0:
        this.text = "האפליקציה הלכה לשרת לחפש את הנתונים, תכף תשוב נראה מה קרה";
        break;
      case 1:
        this.text = "מחפשים בארכיון את כל המידע, מקווה שזה לא דחוף";
        break;
      case 2:
        this.text = "אל תזוזו, כבר מעלים את הדף";
        break;
      case 3:
        this.text = "הבקשה התקבלה, מבצע איחזור נתונים :)";
        break;
      case 4:
        this.text = "אולי לצופים יש אפליקציה מהירה יותר, אבל היא פחות יפה.";
        break;
      case 5:
        this.text = "רק רגע, זה בדרך, עוד קצת, שנייה...";
        break;
      case 6:
        this.text = "רק בודקים בגוגל מה לעשות וחוזרים אליך";
        break;
      case 7:
        this.text = "בודק אם יש וירוס קורונה במחשב לפני שמביאים את כל הנתונים של התנועה";
        break;
      case 8:
        this.text = "לאט ובזהירות, זאת הדרך הנכונה. סבלנות היא מילת המפתח.";
    }

     
  }

}
