import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { LoadingComponent } from '../loading/loading.component';

import { GlobalsService } from '../../shared/services/globals.service'

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.css']
})
export class RequestComponent implements OnInit {
  @ViewChild('iframe') 
  private iframeRef: ElementRef;	

  url : string = "https://script.google.com/macros/s/AKfycbzKo6x3tWizOf0Mm6GmNKMIPP6R8kuPF4ZwS0amX1n9lxjtZjkO/exec?v=form";
  trustedUrl: SafeResourceUrl;

  isLoaded : boolean = false
  year: string;
  s: String = '&y=2020&email='
  constructor(
    public authService: AuthService,
    private renderer: Renderer2,
    public loadingComponent: LoadingComponent,
    private sanitizer: DomSanitizer,
    public globals: GlobalsService
  ) {
   }


  ngOnInit(): void {
    this.globals.chosenYear.subscribe(year => {
      this.year = year;
      this.s = '&y=' + year + "&name=" + this.authService.userData.displayName + '&email='
      this.loadIframe()
    })
  }
  loadIframe(){
    this.isLoaded  = false
    if (this.authService.userData.email){
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url + this.s + this.authService.userData.email );
      } else {
       this.url = "https://script.google.com/macros/s/AKfycbzKo6x3tWizOf0Mm6GmNKMIPP6R8kuPF4ZwS0amX1n9lxjtZjkO/exec?v=405"
       this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      }
  }
   ngAfterViewInit() {
    this.renderer.listen(this.iframeRef.nativeElement, 'load', (e) => { this.isLoaded = true; })
  }
}
