import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';


import { SignInComponent } from '../../components/sign-in/sign-in.component';
import { SignUpComponent } from '../../components/sign-up/sign-up.component';
import { DashboardComponent } from '../../components/dashboard/dashboard.component';
import { ForgotPasswordComponent } from '../../components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from '../../components/verify-email/verify-email.component';
import { RequestComponent } from '../../components/request/request.component';
import { BudgetComponent } from '../../components/budget/budget.component';
import { InfoComponent } from '../../components/info/info.component';
import { MyRequestsComponent } from '../../components/myrequests/myrequests.component' ;
import { IndexComponent } from '../../components/index/index.component';
import { SuppliersComponent } from '../../components/suppliers/suppliers.component';

import { AuthGuard } from "../../shared/guard/auth.guard";

const routes: Routes = [
  { path: 'sign-in', component: SignInComponent},
  { path: '', component: SignInComponent},
  { path: 'request', component: RequestComponent, canActivate: [AuthGuard]},
  { path: 'budget', component: BudgetComponent, canActivate: [AuthGuard]},
  { path: 'info', component: InfoComponent, canActivate: [AuthGuard]},
  { path: 'suppliers', component: SuppliersComponent, canActivate: [AuthGuard]},
  { path: 'myreq', component: MyRequestsComponent, canActivate: [AuthGuard]},
  { path: 'index', component: IndexComponent},
  { path: 'register-user', component: SignUpComponent},
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'verify-email-address', component: VerifyEmailComponent },
  { path: '**', redirectTo: '/sign-in' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes),CommonModule],
  exports: [RouterModule]
})

export class AppRoutingModule { }
