<div class="displayTable">
  <div class="displayTableCell">

    <div class="px-logo">
      <a href="" target="_blank">
        <img src="assets/budgetLogo.jpg" alt="shmutz icon">
      </a>
    </div>

    <div class="authBlock">
      <h3 dir="rtl">תודה שנרשמת!</h3>

      <div class="formGroup" *ngIf="authService.userData as user">
        <p dir="rtl" class="text-center">שלחנו לך מייל אישור לכתובת הבאה: <strong>{{user.email}}</strong>.</p>
        <p dir="rtl" class="text-center"> <strong>שימו לב! רק לאחר לחיצה על הקישור ניתן להיכנס למערכת!</strong></p>
        <p dir="rtl" class="text-center">בבקשה בדוק את תיבת הדואר הנכנס ולחץ על הקישור במייל שנשלח.</p>
        
      </div>

      <!-- Calling SendVerificationMail() method using authService Api -->
      <div class="formGroup">
        <button type="button" class="btn btnPrimary" (click)="authService.SendVerificationMail()">
          <i class="fas fa-redo-alt"></i>
          שלח מחדש את מייל האישור
        </button>
      </div>

    </div>

    <div class="redirectToLogin">
      <span>חזרה ל<span class="redirect" routerLink="/sign-in"> כניסה</span></span>
    </div>

  </div>
</div>
