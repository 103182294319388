import { Component, OnInit } from '@angular/core';
import { LoadingComponent } from '../loading/loading.component';


@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {

  constructor(public loadingComponent: LoadingComponent) { }

  ngOnInit(): void {
  }

}
